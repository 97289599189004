import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Layout from '../../../layouts/Layout';

// About Us page sections
import Information from "pages/LandingPages/Services/sections/Information";
import Featuring from "pages/LandingPages/Services/sections/Featuring";

// Images
import bgImage from "assets/images/bg-about-us.jpg";

function Services() {
	return (
		<Layout>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.6),
							rgba(gradients.dark.state, 0.6)
						)}, url('https://images.pexels.com/photos/7551616/pexels-photo-7551616.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
				}}
			>
				<Container>
					<Grid
						container
						item
						xs={12}
						lg={8}
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						sx={{mx: "auto", textAlign: "center"}}
					>
						<MKTypography
							variant="h1"
							color="white"
							sx={({breakpoints, typography: {size}}) => ({
								[breakpoints.down("md")]: {
									fontSize: size["3xl"],
								},
							})}
						>
							Services Offered at Apt Aide LLC
						</MKTypography>
						<MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
							Our experienced staff has over 15 years of experience in providing service to disabled
							persons and people with serious mental illness. We have nurses and other medical staff available around the clock.
						</MKTypography>
					</Grid>
				</Container>
			</MKBox>
			<Card
				sx={{
					p: 2,
					mx: {xs: 2, lg: 3},
					mt: -8,
					mb: 4,
					boxShadow: ({boxShadows: {xxl}}) => xxl,
				}}
			>
				<Information/>
				<Featuring/>
			</Card>
		</Layout>
	);
}

export default Services;

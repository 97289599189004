import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
	return (
		<MKBox component="section" py={6} my={6}>
			<Container>
				<Grid container item xs={11} spacing={3} alignItems="center" sx={{mx: "auto"}}>
					<Grid item xs={12} lg={4} sx={{mx: "auto"}}>
						<RotatingCard>
							<RotatingCardFront
								image={bgFront}
								icon="keyboard_double_arrow_right_outlined"
								title={
									<>
										Get quality
										<br/>
										Apt Aide Services
									</>
								}
								description="What makes our company unique is that we acknowledge the importance of working with our clients and their families, and other care providers."
							/>
							<RotatingCardBack
								image={bgBack}
								title="Discover More"
								description="We also adopt person-centered holistic care services that cover the overall needs of our clients."
								action={{
									type: "internal",
									route: "/services",
									label: "our services",
								}}
							/>
						</RotatingCard>
					</Grid>
					<Grid item xs={12} lg={7} sx={{ml: "auto"}}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<DefaultInfoCard
									icon="chalet_outlined"
									title="Personalized Care"
									description="Clients receive assistance with daily activities such as bathing, dressing, and medication management."
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DefaultInfoCard
									icon="animation_outlined"
									title="Companion Care"
									description="Services designed to provide emotional support, social interaction, and assistance with daily activities."
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3} sx={{mt: {xs: 0, md: 6}}}>
							<Grid item xs={12} md={6}>
								<DefaultInfoCard
									icon="blur_on_outlined"
									title="Health &amp; Wellness Programs"
									description="We offer fitness programs, health monitoring, and access to healthcare services."
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DefaultInfoCard
									icon="filter_vintage_outlined"
									title="Psychiatric Support &amp; Treatment"
									description="We offer a range of tailored services designed to help clients manage and overcome mental health issues. "
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Information;

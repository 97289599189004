import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import {red} from "@mui/material/colors";

function BuiltByDevelopers() {
	const bgImage =
		"https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";
	
	return (
		<MKBox
			display="flex"
			alignItems="center"
			borderRadius="xl"
			my={2}
			py={6}
			sx={{
				backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
					`${linearGradient(
						rgba(gradients.dark.main, 0.8),
						rgba(gradients.dark.state, 0.8)
					)}, url(${bgImage})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<Container>
				<Grid container item xs={12} lg={6} sx={{ml: {xs: 0, lg: 6}}}>
					<MKBox >
						<MKTypography variant="h4" color="white" fontWeight="bold">
							Welcome to
						</MKTypography>
						<MKTypography variant="h1" color="white" my={1}>
							Apt Aide LLC
						</MKTypography>
					</MKBox>
					<MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
						Are you looking for a company that can provide you or your loved ones with reliable assistance
						wherever they prefer to live in? You are in the right place. We understand that every client has
						their preferences when it comes to needed care. That is why we offer bespoke community living assistance
						to accommodate your choices.
					</MKTypography>
					<MKTypography
						component="a"
						href="/"
						target="_blank"
						rel="noreferrer"
						variant="body2"
						color="white"
						fontWeight="regular"
						sx={{
							display: "flex",
							alignItems: "center",
							
							"& .material-icons-round": {
								fontSize: "1.125rem",
								transform: `translateX(3px)`,
								transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
							},
							
							"&:hover .material-icons-round, &:focus .material-icons-round": {
								transform: `translateX(6px)`,
							},
						}}
					>
						Read more <Icon sx={{fontWeight: "bold"}}>arrow_forward</Icon>
					</MKTypography>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default BuiltByDevelopers;

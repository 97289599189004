import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
	return (
		<MKBox component="section" py={3}>
			<Container>
				<Grid container item xs={12} lg={9} sx={{mx: "auto"}}>
					<Grid item xs={12} md={4}>
						<DefaultCounterCard
							count={6}
							suffix="+"
							title="Years"
							description="We have vast experience caring for patients; you will be in good hands"
						/>
					</Grid>
					<Grid item xs={12} md={4} display="flex">
						<Divider orientation="vertical" sx={{display: {xs: "none", md: "block"}, mx: 0}}/>
						<DefaultCounterCard
							count={15}
							suffix="+"
							title="Staff"
							description="There will always be a member of staff to be with you or your loved one"
						/>
						<Divider orientation="vertical" sx={{display: {xs: "none", md: "block"}, ml: 0}}/>
					</Grid>
					<Grid item xs={12} md={4}>
						<DefaultCounterCard
							count={1}
							title="Location"
							description="We are available in Richmond."
						/>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Counters;

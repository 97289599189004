import Icon from "@mui/material/Icon";

const routes = [
	{
		name: "home",
		icon: <Icon>home</Icon>,
		route: "/",
	},
	{
		name: "about us",
		icon: <Icon>info</Icon>,
		route: "/about-us",
	},
	{
		name: "services",
		icon: <Icon>medical_services</Icon>,
		route: "/services",
	},
	{
		name: "get in touch",
		icon: <Icon>contacts</Icon>,
		route: "/get-in-touch",
	},
];

export default routes;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Images
import coinbase from "assets/images/logos/gray-logos/logo-coinbase.svg";
import nasa from "assets/images/logos/gray-logos/logo-nasa.svg";
import netflix from "assets/images/logos/gray-logos/logo-netflix.svg";
import pinterest from "assets/images/logos/gray-logos/logo-pinterest.svg";
import spotify from "assets/images/logos/gray-logos/logo-spotify.svg";
import vodafone from "assets/images/logos/gray-logos/logo-vodafone.svg";

function Featuring() {
	return (
		<MKBox component="section" pt={3} pb={8}>
			<Container>
				<Grid container justifyContent="center" sx={{textAlign: "center"}}>
					<Grid item xs={12} md={3}>
						<DefaultCounterCard
							count={350}
							separator=","
							title="Clients"
							description="offered tailored services to suit their needs."
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<DefaultCounterCard
							count={5000}
							separator=","
							suffix="+"
							title="Hours"
							description="of care giving."
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<DefaultCounterCard
							count={24}
							suffix="/7"
							title="Availability"
							description="we are always there for our clients."
						/>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Featuring;

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Layout from '../../../layouts/Layout';

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";

function AboutUs() {
	return (
		<Layout>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.6),
							rgba(gradients.dark.state, 0.6)
						)}, url('https://images.pexels.com/photos/7579823/pexels-photo-7579823.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
				}}
			>
				<Container>
					<Grid
						container
						item
						xs={12}
						lg={8}
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						sx={{mx: "auto", textAlign: "center"}}
					>
						<MKTypography
							variant="h1"
							color="white"
							sx={({breakpoints, typography: {size}}) => ({
								[breakpoints.down("md")]: {
									fontSize: size["3xl"],
								},
							})}
						>
							About Apt Aide LLC
						</MKTypography>
						<MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
							Our services aim to help clients reintegrate into their communities. As a Provider Agency of
							the HCBS-AMH program, we focus on adults with a history of psychiatric hospital stays,
							or frequent ER visits.
						</MKTypography>
						<MKTypography variant="h6" color="white" mt={8} mb={1}>
							Find us on
						</MKTypography>
						<MKBox display="flex" justifyContent="center" alignItems="center">
							<MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
								<i className="fab fa-facebook"/>
							</MKTypography>
							<MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
								<i className="fab fa-instagram"/>
							</MKTypography>
							<MKTypography component="a" variant="body1" color="white" href="#" mr={0}>
								<i className="fab fa-twitter"/>
							</MKTypography>
						</MKBox>
					</Grid>
				</Container>
			</MKBox>
			<Card
				sx={{
					p: 2,
					mx: {xs: 2, lg: 3},
					mt: -8,
					mb: 4,
					boxShadow: ({boxShadows: {xxl}}) => xxl,
				}}
			>
				<Information/>
				{/*<Team/>*/}
				{/*<Featuring/>*/}
				{/*<Newsletter/>*/}
			</Card>
		</Layout>
	);
}

export default AboutUs;

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

function Information() {
	return (
		<MKBox component="section" py={12}>
			<Container>
				<Grid
					container
					item
					xs={12}
					lg={6}
					justifyContent="center"
					sx={{mx: "auto", textAlign: "center"}}
				>
					<MKBox>
						<MKTypography variant="h2">Trusted by over </MKTypography>
						<MKTypography variant="h2" color="info" textGradient mb={2}>
							350 clients
						</MKTypography>
						<MKTypography variant="body1" color="text" mb={2}>
							Our commitment to clients is rooted in patience, care, honesty, respect, dignity, and a relentless pursuit of perfection.
						</MKTypography>
					</MKBox>
				</Grid>
				<Grid container spacing={3} sx={{mt: 8}}>
					<Grid item xs={12} md={6} lg={4}>
						<DefaultReviewCard
							name="Nick Peters"
							date="9 days ago"
							review="This is a top notch service provider, the staff are excellent and helped us at a time of need."
							rating={5}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<DefaultReviewCard
							color="info"
							name="Simone Nanaba"
							date="1 week ago"
							review="We have tried a few care providers for my elderly father, and Apt Aide has been the best. Professional staff, very accommodating."
							rating={5}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<DefaultReviewCard
							name="Brenda Lee"
							date="3 weeks ago"
							review="Picking apt aide as my care provider was the right decision."
							rating={5}
						/>
					</Grid>
				</Grid>
				<Divider sx={{my: 6}}/>
			</Container>
		</MKBox>
	);
}

export default Information;

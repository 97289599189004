import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Layout from '../../../layouts/Layout';

// About Us page sections
import Information from "pages/LandingPages/GetInTouch/sections/Information";
import Featuring from "pages/LandingPages/GetInTouch/sections/Featuring";

function Services() {
	return (
		<Layout>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.6),
							rgba(gradients.dark.state, 0.6)
						)}, url('https://images.pexels.com/photos/7195117/pexels-photo-7195117.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
				}}
			>
				<Container>
					<Grid
						container
						item
						xs={12}
						lg={8}
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						sx={{mx: "auto", textAlign: "center"}}
					>
						<MKTypography
							variant="h1"
							color="white"
							sx={({breakpoints, typography: {size}}) => ({
								[breakpoints.down("md")]: {
									fontSize: size["3xl"],
								},
							})}
						>
							Get in touch with Apt Aide LLC
						</MKTypography>
						<MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
							If you have any questions or concerns, we will be glad to address them for you.
						</MKTypography>
					</Grid>
				</Container>
			</MKBox>
			<Card
				sx={{
					p: 2,
					mx: {xs: 2, lg: 3},
					mt: -8,
					mb: 4,
					boxShadow: ({boxShadows: {xxl}}) => xxl,
				}}
			>
				<Information/>
			</Card>
		</Layout>
	);
}

export default Services;

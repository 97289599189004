import {useEffect} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import AboutUs from "layouts/pages/landing-pages/about-us";
import Services from "./layouts/pages/landing-pages/services";
import GetInTouch from "./layouts/pages/landing-pages/get-in-touch";

import Layout from "./layouts/Layout";

export default function App() {
	const {pathname} = useLocation();
	
	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);
	
	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}
			
			if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key}/>;
			}
			
			return null;
		});
	
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<Routes>
				<Route path="*"
				       element={<Navigate to="/"/>}/>
				<Route path={process.env.PUBLIC_URL + "/"}
				       element={<Presentation/>}/>
				<Route path="about-us"
				       element={<AboutUs/>}/>
				<Route path={process.env.PUBLIC_URL + "services"}
				       element={<Services/>}/>
				<Route path={process.env.PUBLIC_URL + "get-in-touch"}
				       element={<GetInTouch/>}/>
			</Routes>
		</ThemeProvider>
	);
}

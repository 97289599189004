import {useState} from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
	const services = [
		{image: 'https://plus.unsplash.com/premium_photo-1674760950940-8f439c3689db?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Residential Support Services', description: 'This is eligible for individuals that have medical conditions or behavioral issues that ' +
				'require constant supervision for their safety. There is 24 hour awake shift staff that assists individuals with medications, dressing, cooking, ' +
				'bathing, community integration and transportation to appointments. Individuals are placed based on the level of need. An individual may have 24 ' +
				'hour one on one staff based on their level of need.', label: ''},
		
		{image: 'https://images.unsplash.com/photo-1587556930799-8dca6fad6d41?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Assisted Living', description: '24-hour on-site services in community-based home environment, with experienced staff in personal care, ' +
				'chores, medication oversight, therapeutic, social, and recreational activities. We can meet scheduled and unpredictable needs in a way that promotes ' +
				'maximum dignity, independence with supervision, safety and security.', label: ''},
		
		{image: 'https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Community Psychiatric Support & Treatment', description: 'Goal directed supports and solution-focused interventions, intended to achieve ' +
				'identified goals or objectives as defined in the individual’s IRP (Individualized Recovery Plan).', label: ''},
		
		{image: 'https://images.unsplash.com/photo-1478061653917-455ba7f4a541?q=80&w=2050&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Recovery Management', description: 'We offer recovery management programs designed to provide individuals and their families with the support ' +
				'and resources they need to maintain lifelong sobriety. We are passionate about equipping and empowering people and their loved ones with the tools to ' +
				'achieve lifelong goals.', label: ''},
		
		{image: 'https://images.unsplash.com/photo-1612277795054-a7a98257dd6d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Nursing Services', description: 'Nursing services are provided through our Registered Nurse or licensed Vocational nurse under the supervision ' +
				'of a Clinical Supervisor RN. Services include ongoing chronic conditions, wound care, medical administration and supervising delegated tasks.', label: ''},
		
		{image: 'https://images.unsplash.com/photo-1491438590914-bc09fcaaf77a?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Foster/Companion Care', description: 'This is an alternative to group home where individuals reside in a family home and are cared for by a family ' +
				'member or other chosen person who provides rehabilitation.', label: ''},
	];
	
	return (
		<MKBox component="section" py={12}>
			<Container>
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<Grid container justifyContent="flex-start">
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="all_inclusive"
										title="Continuity"
										description="We maintain relationships with clients to build trust and provide consistent care."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="auto_mode"
										title="Professionalism"
										description="We maintain high standards of ethics, confidentiality, and professionalism in all interactions."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="bolt"
										title="Quality Assurance"
										description="We are regularly assessing and improvind the quality of care provided."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="hub"
										title="Accessibility"
										description="We offer easy access to services for all clients, including those with disabilities or special needs."
									/>
								</MKBox>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container spacing={3} alignItems="center" mt={6}>
					<Grid item>
						<Grid container spacing={2}>
							{services.map(service => {
								return <Grid item xs={12} md={4}>
									<MKBox mb={5}>
										<CenteredBlogCard
											image={service.image}
											title={service.title}
											description={service.description}
											action={{
												type: "internal",
												route: "#",
												color: "transparent",
												label: service.label,
											}}
										/>
									</MKBox>
								</Grid>
							})}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Information;

import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {Outlet} from "react-router-dom";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import MKBox from "../components/MKBox";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

const Layout = ({children}) => {
	return (
		<Fragment>
			<DefaultNavbar
				routes={routes}
				action={{
					type: "internal",
					route: "#",
					label: "learn more",
					color: "info",
				}}
				sticky
			/>
			
			{children}
			
			<MKBox pt={6} px={1} mt={6}>
				<DefaultFooter content={footerRoutes}/>
			</MKBox>
			
		</Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.node,
	footerBgClass: PropTypes.string
};

export default Layout;

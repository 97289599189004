import {useState} from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
	
	return (
		<MKBox component="section" py={12}>
			<Container>
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<Grid container alignContent="center">
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="location_on"
										title="Address"
										description="1234 Alpha Road, Apt Aide Suites, Houston, Texas"
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="phone"
										title="Phone"
										description="(321) 234 649"
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="access_time"
										title="Offfice Hours"
										description="Mon. - Fri.: 09:00 - 17:00, Weekend: On call services available"
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={3}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="mail"
										title="Email"
										description="info@aptaidellc.com"
									/>
								</MKBox>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Information;

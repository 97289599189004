// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "./assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
	brand: {
		name: "Apt Aide LLC",
		image: logoCT,
		route: "/",
	},
	socials: [
		{
			icon: <FacebookIcon/>,
			link: "#",
		},
		{
			icon: <TwitterIcon/>,
			link: "#",
		},
	],
	menus: [
		{
			name: "company",
			items: [
				{name: "about us", href: "/about-us"},
			],
		},
		{
			name: "resources",
			items: [
				{name: "Site Map", href: "#"},
			],
		},
		{
			name: "help & support",
			items: [
				{name: "contact us", href: "#"},
				{name: "knowledge center", href: "#"},
			],
		},
		{
			name: "Address",
			items: [
				{name: "1234 Alpha Road", href: "#"},
				{name: "Apt Aide Suites", href: "#"},
				{name: "Houston, TX", href: "#"},
				{name: "(321) 234 649"},
			],
		},
	],
	copyright: (
		<MKTypography variant="button" fontWeight="regular">
			&copy; {date} | {" "}
			<MKTypography
				component="a"
				href="https://aptaidellc.com"
				target="_self"
				rel="noreferrer"
				variant="button"
				fontWeight="regular"
			>
				Apt Aide LLC
			</MKTypography>
			.
		</MKTypography>
	),
};

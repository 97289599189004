// @mui material components
import Layout from '../../layouts/Layout';

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

function Presentation() {
	return (
		<Layout>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: `url('https://images.pexels.com/photos/7659688/pexels-photo-7659688.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
					backgroundSize: "cover",
					backgroundPosition: "top",
					display: "grid",
					placeItems: "center",
				}}
			>
				<Container>
					<Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
						<MKTypography
							variant="h1"
							color="white"
							mt={-6}
							mb={1}
							sx={({breakpoints, typography: {size}}) => ({
								[breakpoints.down("md")]: {
									fontSize: size["3xl"],
								},
							})}
						>
							Apt Aide LLC{" "}
						</MKTypography>
						<MKTypography
							variant="body1"
							color="white"
							textAlign="center"
							px={{xs: 6, lg: 12}}
							mt={1}
						>
							Services for various physical, psychological, emotional &amp; social needs.
						</MKTypography>
					</Grid>
				</Container>
			</MKBox>
			<Card
				sx={{
					p: 2,
					mx: {xs: 2, lg: 3},
					mt: -8,
					mb: 4,
					backgroundColor: ({palette: {white}, functions: {rgba}}) => rgba(white.main, 0.8),
					backdropFilter: "saturate(200%) blur(30px)",
					boxShadow: ({boxShadows: {xxl}}) => xxl,
				}}
			>
				<Counters/>
				<Information/>
				<Container sx={{my: 6}}>
					<BuiltByDevelopers/>
				</Container>
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<FilledInfoCard
								variant="gradient"
								color="info"
								icon="flag"
								title="Getting Started"
								description="We support clients in their chosen living environment with their daily living and mental health needs."
								action={{
									type: "internal",
									route: "/",
									label: "Let's start",
								}}
							/>
						</Grid>
						<Grid item xs={12} lg={4}>
							<FilledInfoCard
								color="info"
								icon="schedule"
								title="Set an appointment"
								description="Schedule an appointment with us for a discussioin of our services."
								action={{
									type: "external",
									route: "/",
									label: "Set Appointment",
								}}
							/>
						</Grid>
						<Grid item xs={12} lg={4}>
							<FilledInfoCard
								color="info"
								icon="directions"
								title="Send your referrals"
								description="Do you know someone who may be in need of our services? Refer them to us!"
								action={{
									type: "external",
									route: "/",
									label: "Send Referral",
								}}
							/>
						</Grid>
					</Grid>
				</Container>
				<Testimonials/>
				<Download/>
				<MKBox pt={18} pb={6}>
					<Container>
						<Grid container spacing={3}>
							<Grid item xs={12} lg={5} ml="auto" sx={{textAlign: {xs: "center", lg: "left"}}}>
								<MKTypography variant="h4" fontWeight="bold" mb={0.5}>
									Thank you for your support!
								</MKTypography>
								<MKTypography variant="body1" color="text">
									We strive for excellence in our service to clients.
								</MKTypography>
							</Grid>
							<Grid
								item
								xs={12}
								lg={5}
								my={{xs: 5, lg: "auto"}}
								mr={{xs: 0, lg: "auto"}}
								sx={{textAlign: {xs: "center", lg: "right"}}}
							>
								<MKSocialButton
									component="a"
									href="/"
									target="_blank"
									color="twitter"
									sx={{mr: 1}}
								>
									<i className="fab fa-twitter"/>
									&nbsp;Tweet
								</MKSocialButton>
								<MKSocialButton
									component="a"
									href="/"
									target="_blank"
									color="facebook"
									sx={{mr: 1}}
								>
									<i className="fab fa-facebook"/>
									&nbsp;Share
								</MKSocialButton>
							</Grid>
						</Grid>
					</Container>
				</MKBox>
			</Card>
		</Layout>
	);
}

export default Presentation;

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
	return (
		<MKBox component="section" py={12}>
			<Container>
				<Grid container spacing={3} alignItems="center">
					<Grid item xs={12} lg={6}>
						<Grid container justifyContent="flex-start">
							<Grid item xs={12} md={6}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="ac_unit"
										title="Mission"
										description="We are committed to providing high quality multidisciplinary services to adults experiencing serious mental illness.
											Our work is led with a belief that clients deserve to be the driver of their own recovery process."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="hourglass_top"
										title="Vision"
										description="We strive to be a leading Provider Agency ensuring comprehensive care for our clients."
									/>
								</MKBox>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} lg={4} sx={{ml: "auto", mt: {xs: 3, lg: 0}}}>
						<CenteredBlogCard
							image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
							title="Get insights on Our Services"
							description="The arrays of services are designed to be appropriate to each individual’s needs, to enable them to live and experience successful tenure in their chosen community."
							action={{
								type: "internal",
								route: "#",
								color: "info",
								label: "find out more",
							}}
						/>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Information;
